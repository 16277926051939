<template>
<div class='cont'>
  <div class="header">
    <h2>{{$t('m.merchantworkbench.Order_details')}}</h2>
    <!-- <el-button>联系买家</el-button> -->
  </div>
  <el-divider></el-divider>

  <div class="body">
    <div class="detail_title">
      <el-tag v-if="omsOrder.orderType==10" size="large" effect="dark" type="danger">样品订单</el-tag>
      <p class="detail_title_p">
        {{$t('m.personalcenter_myorder.order_status')}}：
        <span class="title_p_span">{{
          status == 0
              ? "待付款"
              : status == 1
              ? "已付款"
              : status == 5
              ? "待发货"
              : status == 2
              ? "卖家待备货"
              : status == 3
              ? "待确认备货"
              : status == 12
              ? "待付尾款"
              : status == 8
              ? "待确认发票"
              : status == 4
              ? "拒绝确认备货"
              : status == 6
              ? "待收货"
              : status == 7
              ? "已收货"
              : status == 9
              ? "已完成"
              : status == 10
              ? "已取消"
              : status == 11
              ? "订单失败"
              : status == 15
              ? "预订成功"
              : status == 16
              ? "预订失败"
              : status == 17
              ? "待确认"
              : status == 30
              ? "待付款-预提"
              : status == 31
              ? "待付款-待预提"
              : status == 32
              ? "已付款-待预提"
              : status == 33
              ? "卖家待确认预提"
              : status == 34
              ? "待确认预提"
              : status == 35
              ? "待重新预提"
              : status == 40
              ? "转售中"
              : ""}}</span>
        {{$t('m.personalcenter_myorder.order_detail_number')}}：
        <span>{{orderSn}}</span>
      </p>
      <span class="detail_title_span">
        <!-- <button class="detail_title_button">付款提醒</button> -->
        <button class="detail_title_button" v-if="status == 0" @click="dialogVisible = true">修改订单价格</button>
      </span>
    </div>
    <div class="detail_cont">
      <h3>{{$t('m.personalcenter_myorder.order_information')}}</h3>
      <div class="detail_cont_div">
        <div class="cont_div_msg">{{$t('m.merchantworkbench.User_ID')}}：
          <span class="div_msg_span">{{pmsProduct.id}}</span>
        </div>
        <div class="cont_div_msg" v-if="omsOrder.takeGoodsType == 1">{{$t('m.personalcenter_myorder.consignee')}}：
          <span class="div_msg_span">{{omsOrder.receiverName}}</span>
        </div>
        <div class="cont_div_msg" v-if="omsOrder.takeGoodsType == 1">{{$t('m.merchantworkbench.contact_information')}}：
          <span class="div_msg_span">{{companyPhoneNumber}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.merchantworkbench.Shipping_Method')}}：
          <span class="div_msg_span">{{
            omsOrder.takeGoodsType == 0
              ? "自提"
              : omsOrder.takeGoodsType == 1
              ? "物流"
              : ""}}</span>
        </div>
        <div class="cont_div_msg">{{omsOrder.takeGoodsType == 0
              ? `${$t('m.buy.place_of_delivery')}：`
              : omsOrder.takeGoodsType == 1
              ? `${$t('m.buy.shipping_address')}：`:''}}
          <span class="div_msg_span">{{
            omsOrder.takeGoodsType == 0 // 自提
              ? takeAddrVOS.name
              : omsOrder.takeGoodsType == 1 // 物流
              ? omsOrder.receiverDetailAddress
              : "暂无"
            }}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.personalcenter_myorder.Created_in')}}：
          <span class="div_msg_span">{{omsOrder.createTime}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy_settleaccounts.Payment_Methods')}}：
          <span class="div_msg_span">{{pmsProduct.payWay}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.personalcenter_myorder.Delivery_date')}}：
          <span class="div_msg_span">{{omsOrder.takeGoodsTime}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.merchantworkbench.Contract')}}：
          <span class="div_msg_span" style="color: #C6824E;cursor: pointer;"
          v-if="omsOrderContract"
            @click="tolook(omsOrderContract.path)"
            >《{{omsOrderContract.title}}》</span>
        </div>
        <div class="cont_div_msg">{{$t('m.merchantworkbench.Documents')}}：
          <span class="div_msg_span" v-if="omsOrder.waybillPicture"
          style="color: #C6824E;cursor: pointer;"
          @click="tolook(omsOrder.waybillPicture)"
          >《运单》、</span>
          <span class="div_msg_span" v-if="omsOrder.policyPicture"
          style="color: #C6824E;cursor: pointer;"
          @click="tolook(omsOrder.policyPicture)"
          >《保单》、</span>
          <span class="div_msg_span" v-if="omsOrder.billOfLadingPicture"
          style="color: #C6824E;cursor: pointer;"
          @click="tolook(omsOrder.billOfLadingPicture)"
          >《提单》</span>
        </div>
        <div class="cont_div_msg">{{$t('m.merchantworkbench.Buyer_message')}}：
          <span class="div_msg_span">{{detailaddress}}</span>
        </div>
      </div>
      <h3>{{$t('m.merchantworkbench.Fee_information')}}</h3>
      <div class="detail_cont_div2">
        <img :src="omsOrderItem.productPic" alt="">
        <div class="div2cont">
          <div class="cont_div2_msg">{{$t('m.index.product_name')}}：
            <span class="div_msg_span">{{omsOrderItem.productName}}</span>
          </div>
          <div class="cont_div2_msg">{{$t('m.reservation_varietiy_details.specification')}}：
            <span class="div_msg_span">{{productAttr || ''}}</span>
          </div>
          <div class="cont_div2_msg">{{$t('m.reservation_varietiy_details.number')}}
            <span class="div_msg_span">{{omsOrderItem.productQuantity+pmsProduct.unit}}</span>
          </div>
          <div class="cont_div2_msg">{{$t('m.merchantworkbench.Transferred_quantity')}}：
            <span class="div_msg_span">{{omsOrder.resaleNum+pmsProduct.unit}}</span>
          </div>
          <div class="cont_div2_msg">{{$t('m.buy.unit_price')}}：
            <span class="div_msg_span">{{currencySymbol+omsOrderItem.productPrice+'/'+pmsProduct.unit}}</span>
          </div>
          <!-- <div class="cont_div2_msg">优惠券：<span class="div_msg_span">12312312</span></div> -->
          <!-- <div class="cont_div2_msg">折 扣：<span class="div_msg_span">12312312</span></div> -->
          <div class="cont_div2_msg">{{$t('m.reservation_varietiy_details.Delivery_method')}}：
            <span class="div_msg_span">{{
              omsOrder.takeGoodsType == 0
                ? "自提:"
                : omsOrder.takeGoodsType == 1
                ? "物流"
                : "暂无"}}</span>
          </div>
          <div class="cont_div2_msg">{{$t('m.personalcenter_myorder.Accrued_expenses')}}：
            <span class="div_msg_span">{{currencySymbol+(omsOrderInfo!=null&&omsOrderInfo.packFee?omsOrderInfo.packFee:0)}}</span>
          </div>
          <div class="cont_div2_msg">{{$t('m.merchantworkbench.Stock_quantity')}}：
            <span class="div_msg_span">{{omsOrder.stockUpAmount}}</span>
          </div>
          <div class="cont_div2_msg">{{$t('m.merchantworkbench.Stock_Difference')}}：
            <span class="div_msg_span">{{currencySymbol+beihuoTestMoney}}</span>
          </div>
          <div class="cont_div2_msg">{{$t('m.merchantworkbench.Logistics_fee')}}：
            <span class="div_msg_span">{{currencySymbol+wuliuMoney}}</span>
          </div>
          <div class="cont_div2_msg plus">{{$t('m.merchantworkbench.accounts_payable')}}：
            <span class="div_msg_span">{{currencySymbol+omsOrder.totalAmount}}</span>
          </div>
          <div class="cont_div2_msg plus">{{$t('m.merchantworkbench.Paid')}}：
            <span class="div_msg_span">{{currencySymbol+omsOrder.payAmount}}</span>
          </div>
        </div>
      </div>
      <h3>{{$t('m.buy.invoice_information')}}</h3>
      <!-- 普通发票 -->
      <div class="detail_cont_div3" v-if="umsMemberBill.billType == 0">
        <div class="cont_div_msg">{{$t('m.buy.invoice_title')}}：
          <span class="div_msg_span">{{umsMemberBill.billHeader}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.taxpayer_identification_number')}}：
          <span class="div_msg_span">{{umsMemberBill.billCode}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.check_taker')}}：
          <span class="div_msg_span">{{umsMemberBill.billReceiverName}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.Ticket_collection_telephone')}}：
          <span class="div_msg_span">{{umsMemberBill.billReceiverPhone}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.Receipt_address')}}：
          <span class="div_msg_span">{{umsMemberBill.billReceiverAddress}}</span>
        </div>
      </div>
      <!-- 普通增值税发票 -->
      <div class="detail_cont_div3" v-if="umsMemberBill.billType == 1">
        <div class="cont_div_msg">{{$t('m.buy.invoice_title')}}：
          <span class="div_msg_span">{{umsMemberBill.billHeader}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.taxpayer_identification_number')}}：
          <span class="div_msg_span">{{umsMemberBill.billCode}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.check_taker')}}：
          <span class="div_msg_span">{{umsMemberBill.billReceiverName}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.Ticket_collection_telephone')}}：
          <span class="div_msg_span">{{umsMemberBill.billReceiverPhone}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.Receipt_address')}}：
          <span class="div_msg_span">{{umsMemberBill.billReceiverAddress}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.deposit_bank')}}：
          <span class="div_msg_span">{{umsMemberBill.billBank}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.account_number')}}：
          <span class="div_msg_span">{{umsMemberBill.billBankAccount}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.Account_phone_number')}}：
          <span class="div_msg_span">{{umsMemberBill.billPhone}}</span>
        </div>
        <div class="cont_div_msg">{{$t('m.buy.business_address')}}：
          <span class="div_msg_span">{{umsMemberBill.billAddress}}</span>
        </div>
      </div>
      <!-- <h3>操作日志</h3>
      <div class="">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="sn" label="编号" />
          <el-table-column prop="name" label="操作人" />
          <el-table-column prop="time" label="操作时间" />
          <el-table-column prop="act" label="操作行为" />
        </el-table>
      </div> -->
    </div>

    <el-dialog
      v-model="dialogVisible"
      title="修改订单价格"
      width="30%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <p class="up_price">原单价：{{currencySymbol+omsOrderItem.productPrice+' / '+pmsProduct.unit}}</p>
      <el-input-number v-model="updNum" :precision="2" :min="0"
      controls-position="right"/>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="updatePriceFun()">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</div>
</template>

<script>
import { ref } from 'vue-demi'
import http from '../../../api/http'
import { ElLoading, ElMessage } from 'element-plus'
// import { getStore } from '../../../tools/storage'
// import emitter from '../../../tools/bus'
const tableData = []
// 查询订单详情
const getOrderInfoByOrderId = (data) => {
  return http.get(`/mall-order/order/getOrderInfoByOrderId/${data}`)
}
// 修改订单价格
const updatePrice = (data) => {
  return http.post('/mall-merchant/order/updatePrice', data)
}
export default {
  name: 'MerchantOrderDetail',
  data () {
    return {
      orderType: '',
      orderSn: '',
      // orderTableTitle: ['商品名称', '规格', '单价', '数量', '预提方式', '预提费用'],
      takeAddrVOS: ref(''),
      pmsBillHolding: ref(''),
      currencySymbol: ref(''), // 货币种类
      companyName: ref(''), // 商家名字
      companyPhoneNumber: ref(''), // 商家电话
      pmsProduct: ref(''), // 商家信息
      omsOrder: ref(''), // 订单信息
      detailaddress: ref(''), // 留言
      omsOrderItem: ref(''), // 商品信息 =>规格
      productAttr: ref(''),
      omsOrderInfo: ref(''), // 预提费
      omsOrderContract: '', // 合同信息

      preTakeWay: ref(''), // 预提方式
      preTakeWayValue: ref(''), // 已选预提方式
      areaValue: ref(''), // 已选地址方式
      isEnter: 1, // 选择配送方式
      // advancedisabled: false, // 提交预提按钮 是否禁用

      amountList: ref(''), // 额外费用
      wuliuMoney: 0, // 物流费
      beihuoTestMoney: 0, // 备货差价

      status: '',
      umsMemberBill: '', // 发票信息  发票类型：0->普通发票；1->普增专票
      logisticsVos: '', // 买家地址信息
      takeProductAddressId: '',

      // 修改订单价格
      dialogVisible: ref(false),
      updNum: 0
    }
  },
  components: {},
  methods: {
    async initData (data) {
      const loading = this.$loading({
        lock: true,
        text: '请稍等...'
      })
      const res = await getOrderInfoByOrderId(data)
      console.log(res)
      const result = res.data.data
      if (res.data.code === 200) {
        this.takeAddrVOS = res.data.data.takeAddrVOS
        this.pmsBillHolding = res.data.data.pmsBillHolding
        this.currencySymbol = result.pmsProduct.currencySymbol || '' // 货币种类
        this.companyName = result.companyName // 商家名字
        this.companyPhoneNumber = result.companyPhoneNumber // 商家电话
        this.pmsProduct = result.pmsProduct // 商家信息
        this.omsOrder = result.omsOrder // 订单信息
        this.detailaddress = result.omsOrder.note // 留言
        this.omsOrderItem = result.omsOrderItem // 商品信息
        if (result.omsOrderContract !== null && result.omsOrderContract.length !== 0) {
          this.omsOrderContract = result.omsOrderContract[0] // 合同信息
        }
        this.omsOrderInfo = result.omsOrderInfo
        this.status = this.omsOrder.status
        this.umsMemberBill = result.umsMemberBill
        this.logisticsVos = result.umsMemberReceiveAddressList
        this.amountList = result.amountList // 判断费用明细

        // 规格处理
        this.omsOrderItem.productAttr = JSON.parse(this.omsOrderItem.productAttr)
        if (this.status === 0 || this.status === 32) {
          if (this.omsOrderItem.productAttr.length >= 2) {
            this.productAttr = this.omsOrderItem.productAttr[1].value
          } else {
            this.productAttr = this.omsOrderItem.productAttr[0].value
          }
          // this.productAttr = this.omsOrderItem.productAttr[1].value
        } else {
          if (this.omsOrderItem.productAttr.length >= 2) {
            this.productAttr = this.omsOrderItem.productAttr[1].value || this.omsOrderItem.productAttr[0].value
          } else {
            this.productAttr = this.omsOrderItem.productAttr[0].value
          }
          // this.productAttr = this.omsOrderItem.productAttr[1].value || this.omsOrderItem.productAttr[0].value
        }
        // 备货差价
        for (const i of this.amountList) {
          // console.log(i)
          if (i.name === '物流费') {
            this.wuliuMoney = i.productId
            continue
          } else if (i.name === '备货差价') {
            this.beihuoTestMoney = i.productId
            continue
          } else if (i.name === '预提费用') {
            continue
          } else {
            this.wuliuMoney = 0
            this.beihuoTestMoney = 0
          }
        }
        loading.close()
      }
    },
    updatePriceFun () {
      this.$confirm(`确认修改单价为${this.currencySymbol + this.updNum + ' / ' + this.pmsProduct.unit}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const data = {
            price: this.updNum,
            id: this.omsOrder.id
          }
          const loading = ElLoading.service({
            lock: true,
            text: '请稍等...'
          })
          console.log(data)
          updatePrice(data).then((res) => {
            console.log(res)
            if (res.data.code === 200) {
              ElMessage.success({
                message: res.data.msg
              })
              this.initData(this.$props.datadetail.orderSn)
              this.dialogVisible = false
            } else {
              ElMessage.warning({
                message: res.data.msg
              })
            }
            loading.close()
          }).catch((err) => {
            console.log(err)
            loading.close()
          })
          loading.close()
        })
        .catch(() => {})
    },
    handleClose (done) {
      this.$confirm('确认关闭?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.updNum = 0
          done()
        })
        .catch(() => {})
    },
    tolook (url) {
      window.open(url)
    }
  },
  props: ['datadetail'],
  created () {
    // if (getStore('merchantorderdetail')) {
    if (this.$props.datadetail) {
      const detail = this.$props.datadetail
      this.orderType = detail.orderType
      this.orderSn = detail.orderSn
      this.initData(detail.orderSn)
    }
    // this.initData(this.orderSn)
  },
  setup (props, ctx) {
    return {
      tableData
    }
  }
}
</script>
<style lang='scss' scoped>
@import '../../../style/viriables.scss';
.cont{
  .header{
    display: flex;
    padding: 0 20px;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    h2{
      font-size: 22px;
      font-weight: 500;
    }
  }
  .body{
    .detail_title{
      height: 69px;
      padding: 0 20px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .el-tag{
        margin-right: 20px;
      }
      .detail_title_p{
        font-size: 14px;
        color: #333333;
        .title_p_span{
          padding-right: 20px;
          font-size: 18px;
          color: #C6824E;
          font-weight: bold;
        }
      }
      .detail_title_span{
        .detail_title_button{
          margin-left: 20px;
          padding: 0 20px;
          height: 30px;
          background: linear-gradient(0deg, #280E01 0%, #673000 100%);
          border: 1px solid #EAEAEA;
          border-radius: 4px;
          color: #ffffff;
        }
      }
    }
    .detail_cont{
      h3{
        height: 40px;
        padding: 0 20px;
        background: #F7F7F7;
        line-height: 40px;
      }
      .detail_cont_div{
        padding:20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .cont_div_msg{
          min-width: 33.33%;
          line-height: 40px;
          color: #999999;
          .div_msg_span{
            color: #333333;
          }
        }
      }
      .detail_cont_div2{
        padding: 20px;
        display: flex;
        img{
          height: 120px;
          width: 120px;
          margin-right: 20px;
        }
        .div2cont{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .cont_div2_msg{
            min-width: 33.33%;
            line-height: 30px;
            color: #999999;
            .div_msg_span{
              color: #333333;
            }
          }
          .plus{
            font-weight: bold;
            color: #C6824E;
            .div_msg_span{
              color: #C6824E;
            }
          }
        }
      }
      .detail_cont_div3{
        padding:20px;
        display: flex;
        flex-flow: column;
        .cont_div_msg{
          // min-width: 33.33%;
          line-height: 40px;
          color: #999999;
          .div_msg_span{
            color: #333333;
          }
        }
      }
    }
    .up_price{
      font-size: 18px;
      line-height: 60px;
      color: #C6824E;
      font-weight: bold;
    }
  }
}
</style>
